import * as React from "react";
import { navigate } from "gatsby-link";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Navbar from "../components/Navbar";
import Content, { HTMLContent } from "../components/Content";
import Gallery from "../components/Gallery";
import iamatika1 from "../../static/videos/01_IamatikaAradoura_Aerial.mp4";
import iamatika2 from "../../static/videos/02_IamatikaAradoura_outside.mp4";
import iamatika3 from "../../static/videos/03_IamatikaAradoura_inside.mp4";
import altour_video from "../../static/videos/alternative_tourism5.mp4";
import VideoGallery from "../components/VideoGallery";

export default function ExplorePage({ data }) {
  const { frontmatter: page } = data.page;
  //   const PageContent = HTMLContent || Content;
  return (
    <Layout>
      <Navbar lang={page.language} slug={data.page.fields.slug} />
      <StaticImage
        src="../img/pool2.jpg"
        alt="contact"
        placeholder="blurred"
        objectFit={"cover"}
        layout="fullWidth"
        aspectratio={3 / 1}
      />
      <section
        className="section"
        style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
      >
        <div className="container">
          <div className="content">
            {/* <PageContent className="content" content={data.page.html} /> */}
            <h1>{page.section1Title}</h1>
            <h2>{page.village1}</h2>
            <Gallery
              gallery={Array.from({ length: 25 })
                .map((currentElement, i) => i)
                .map((r) => `ammoudara_${r + 1}.jpg`)}
            />
            <h2>{page.village2}</h2>
            <Gallery
              gallery={Array.from({ length: 25 })
                .map((currentElement, i) => i)
                .map((r) => `amarantos_${r + 1}.jpg`)}
            />
            <h2>{page.village3}</h2>
            <Gallery
              gallery={[
                "preveza_1.jpg",
                "preveza_2.jpg",
                "preveza_3.jpg",
                "preveza_4.jpg",
              ]}
            />
            {console.log("page", page)}
            <h2>{page.village4}</h2>
            <Gallery
              gallery={[
                "sarandaporo.jpg",
                "sarandaporo1.jpg",
                "sarandaporo2.jpg",
                "sarandaporo3.jpg",
                "sarandaporo4.jpg",
                "sarandaporo6.jpg",
              ]}
            />
            <h2>{page.village5}</h2>
            <Gallery
              gallery={[
                "benja.jpg",
                "benja1.jpg",
                "benja_two.jpg",
                "benja_three.jpg",
              ]}
            />

            <h1>{page.section2Title}</h1>
            <div className="container is-flex is-justify-content-center" >
              <video controls>
                <source src={altour_video} type="video/mp4" />
              </video>
            </div>
            <h2>{page.village1}</h2>
            <VideoGallery videos={[iamatika1, iamatika2, iamatika3]} />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const ExplorePageQuery = graphql`
  query ExplorePageById($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      html
      frontmatter {
        language
        title
        section1Title
        section2Title
        village1
        village2
        village3
        village4
        village5
      }
    }
  }
`;
