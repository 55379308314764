import React, { useState } from "react";
import Slider from "react-slick";

const VideoGallery = ({ videos }) => {
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  });
  return (
    <Slider {...settings}>
      {videos.map((v, index) => (
        <div key={index} className="pl-1 pr-1">
          <video controls>
            <source src={v} type="video/mp4" />
          </video>
        </div>
      ))}
    </Slider>
  );
};

export default VideoGallery;
